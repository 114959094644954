import LoginFormHorizontal from './LoginFormHorizontal.vue';
import { scrollToElement } from '@/utils/helpers';
import textCapitalize from '@/filters/text_formatters';

export default {
  name: 'LandingHeader',
  props: {
    companyLogo: {
      type: String,
    },
    partnerLogo: {
      type: String,
    },
    height: {
      type: Number,
      default: 100,
    },
  },
  components: {
    LoginFormHorizontal,
  },
  data() {
    return {
      sportType: process.env.VUE_APP_SPORT_TYPE,
      disableLinks: null,
    };
  },
  computed: {
    style() {
      const maxWidth = this.$vuetify.breakpoint.xl ? `${this.$vuetify.breakpoint.thresholds.lg}px` : 'initial';
      return { 'max-width': maxWidth };
    },
    createAccountLink() {
      return this.$store.hasModule('servicePlan') ? { hash: 'plan-types' } : { name: 'account.register' };
    },

    sportTypeCapitalized() {
      return textCapitalize(this.sportType);
    },
  },

  methods: {
    submitting(e) {
      this.disableLinks = e;
    },
    createAccountClick() {
      if (!this.$store.hasModule('servicePlan')) {
        this.$router.push({
          name: 'account.register',
        });
      } else {
        scrollToElement('plan-types');
      }
    },
  },
};
